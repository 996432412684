import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CodeResponse, CredentialResponse } from '@react-oauth/google';
import { Delete, iServerError, Post } from '../Server';
import { appError, clearAppError } from './appErrorSlice';

interface iServerUser{
  success: boolean;
  state: string;
  expires_at: number;
  user: {
    email: string;
    picture: string;
    last_sign_in_at: string;
  };
}

export interface iAuthState {
  state: string | null;
  error: string | null;
  expires: number;
  isLoading: boolean;
  user: iServerUser['user'] | null;
}

export const authState = () : iAuthState => {
  var uState = localStorage.getItem("state") || "";
  if( uState === "" ) {
    return {
      state: null,
      error: null,
      expires: 0,
      isLoading: false,
      user: null
    }
  } else {
    var user = JSON.parse(uState);
    return user;
  }
}
const initialState: iAuthState = authState();

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loggingIn: state => {
      state.error = null;
      state.expires = 0;
      state.isLoading = true;
    },
    loggedIn: (state, action: PayloadAction<iServerUser>) => {
      state.error = null;
      state.isLoading = false;
      state.state = action.payload.state;
      state.expires = ( (action.payload.expires_at * 1000) + (60 * 60 * 4 * 1000) );
      state.user = action.payload.user;
      localStorage.setItem("state", JSON.stringify(state));
    },
    loggingOut: state => {
      state.isLoading = true;
    },
    logOut: (state) => {
      state.isLoading = false;
      state.error = null;
      state.user = null;
      state.state = null;
      state.expires = 0;
      localStorage.removeItem("state");
    },
    authError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  }
});

const { loggingIn, loggedIn, loggingOut, logOut, authError } = authSlice.actions;
export {logOut, authError};
export default authSlice.reducer;

export const authUserCred = (gCode: CredentialResponse) => async (dispatch: any) => {
  try {
    dispatch( loggingIn() );
    dispatch( clearAppError() );
    const user = await Post('/sessions/google/create.json?', gCode);
    dispatch(loggedIn(user as iServerUser));
    // history.push('/v1');
  } catch (err) {
    dispatch( appError(err as iServerError) );
    dispatch(authError(err));
  }
};

export const authUser = (gCode: CodeResponse) => async (dispatch: any) => {
  try {
    dispatch( loggingIn() );
    dispatch( clearAppError() );
    const user = await Post('/sessions/google/create.json?', gCode);
    dispatch(loggedIn(user as iServerUser));
    // history.push('/v1');
  } catch (err) {
    dispatch( appError(err as iServerError) );
    dispatch(authError(err));
  }
};

export const authLogout = () => async (dispatch: any) => {
  try {
    dispatch(loggingOut());
    await Delete('/sessions/me.json');
    dispatch(logOut());
    // history.push('/v1');
  } catch (err) {
    dispatch( appError(err as iServerError) );
    dispatch(authError(err));
  }
};