import { platformConfig } from './config/platform';
import { authState } from './redux/authSlice';

export type iServerError = {
  success: boolean;
  error: {
    code: number,
    type?: string,
    message: string
  }
}

export const createErrorObject = (code:number, type:string, message:string):iServerError => {
  return (
    {
      success: false,
      error: {
        code: code,
        type: type,
        message: message
      }
    }
  );
}


//Would be nice to get from redux state but couldn't get working
function setHeaders(form:boolean) { 
  const {state} = authState();
  return {
    'Authorization': `Bearer ${state}`,
    'Content-Type': (form ? 'multipart/form-data' : 'application/json'),
    'Time-Zone': `{"offset": "${(new Date().getTimezoneOffset()/60*-1)}", "timezone": "${Intl.DateTimeFormat().resolvedOptions().timeZone}"}`
  };
};

const processFetch = (fetchResponse:any) => {
  return new Promise((resolve,reject) => {
    fetchResponse
    .then((response:any)=>{
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        response.json().then((json:JSON) => {
          if( response.ok ) {
            return resolve(json);
          } else {
            return reject( json );
          }        
        });
        return;
      } 
      
      response.text()
      .then((data:any)=>{
        if( response.ok ) {
          return resolve(data)
        } else {
          return reject(
            createErrorObject( response.status, response.statusText, response.statusText ) 
          );
        }
      })
    })
    .catch((errorObject:any) =>{
      return reject(
        createErrorObject( 500, 'Server Error', "Error reaching server!" ) 
      );
    });

  });
};

//So path.resolve doesn't work well. If you add /xxx the all hell breaks loose.
const fullPath = (urlPath:string) => {
  let path;
  if(urlPath.charAt(0) === '/') {
    path = `${platformConfig.apiUrl}${urlPath}`;
  } else {
    path = `${platformConfig.apiUrl}/${urlPath}`;
  }
  return path;
}

export const Get = async (UrlPath:string) => {
  return processFetch(
    fetch(fullPath(UrlPath), {
      method: 'GET',
      headers: setHeaders(false)
    })
  );  
}

export const Post = async (UrlPath:string, postData:any, form=false) => {
  const data = form ? postData : JSON.stringify(postData);
  return processFetch(
    fetch(fullPath(UrlPath), {
      method: 'POST',
      headers: setHeaders(form),
      body: data,
    })

  );  
}

export const Put = async (UrlPath:string, postData:any, form=false) => {
  const data = form ? postData : JSON.stringify(postData);
  return processFetch(
    fetch(fullPath(UrlPath), {
      method: 'PUT',
      headers: setHeaders(form),
      body: data,
    })

  );  
}

export const Delete = async (UrlPath:string) => {
  return processFetch(
    fetch(fullPath(UrlPath), {
      method: 'DELETE',
      headers: setHeaders(false)
    })

  );  
}