import React, { useEffect, useState } from "react";
import { Button, Container, Spinner} from "react-bootstrap";
import { GoogleLogin, CredentialResponse, useGoogleLogin } from '@react-oauth/google';
import { connect } from 'react-redux';
import { useAppDispatch } from '../hooks';
import { RootState } from "../platform/redux/store";
import { authLogout, authError, authUserCred, iAuthState, authUser } from '../platform/redux/authSlice';
import UserCard from "../components/UserCard";
import { Link } from "react-router-dom";
import { iAppErrorState } from "../platform/redux/appErrorSlice";

interface iAccount {
  auth: iAuthState;
  appError: iAppErrorState;
}

const Account = (props:iAccount) => {
  const {auth,appError} = props ;
  const dispatch = useAppDispatch();  
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    console.log("useEffect()")
    setIsBusy(auth.isLoading)
  // eslint-disable-next-line
  },[auth.isLoading]);

  const gCredLogin = (response: CredentialResponse) => {
    setIsBusy(true);
    dispatch( authUserCred(response) );
  };

  const gCredError = () => {
    dispatch( authError("Error with login") )
  };

  const gCodeLogin = useGoogleLogin({
    onSuccess: codeResponse => {
      dispatch( authUser(codeResponse) );
    },
    onError: responseMessage => {
      dispatch( authError(responseMessage) )
    },
    onNonOAuthError(nonOAuthError) {alert("No Auth") },
      scope: 'email',
      flow: 'auth-code'
  });

  const logout = () => {
    if( window.confirm("Are you sure you want to logout?") ) {
      setIsBusy(true);
      dispatch( authLogout() );
    } 
  }

  const isExpired = () => {
    if( !auth.state ) return true;
    return ( auth.expires <= (new Date().getTime()) );
  }

  const UserFragment = () => {
    if(isExpired() || appError.error?.code===401) {
      return (
        <React.Fragment>
          <GoogleLogin onSuccess={gCredLogin} onError={gCredError} useOneTap={true}/>
          <Button onClick={() => gCodeLogin()}>Google Login </Button>
        </React.Fragment>
      )
    } else {
      return (
        <Container>
          <UserCard auth={auth} onClick={() => logout()} />
          <Link to="/trades">My Trades</Link>
        </Container>
      )
    }
  }

  return (
    <Container>
      {isBusy ? <Spinner animation="border" role="status" /> : <UserFragment />}
    </Container>
  );
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth as iAuthState,
  appError: state.appError as iAppErrorState,
});

const AccountContainer = connect(mapStateToProps)(Account);
export default AccountContainer;