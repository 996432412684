import { useEffect, useState } from "react";
import { Accordion, Card, Container, Spinner, Table } from "react-bootstrap";
import { RootState } from "../platform/redux/store";
import { useAppDispatch } from "../hooks";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { iAppErrorState } from "../platform/redux/appErrorSlice";
import { getFundHoldings, iFundState, typeFund, typeHolding } from "../platform/redux/fundsSlice";
import FormattedNumber from "../components/FormattedNumber";
import {v1 as uuid} from "uuid"
import { Link } from "react-router-dom";
import { FundCard } from "../components/FundCard";

interface iFunds {
  appError: iAppErrorState,
  fund: iFundState
}

const Fund = (props:iFunds) => {
  const {fund} = props ;
  const [isBusy, setIsBusy] = useState(true);
  const [holdings, setHoldings] = useState([] as typeHolding[][]);
  const [tickerArr, setTickerArr] = useState([] as number[])

  const {fund_id}  = useParams();
  const fundID:number = parseInt(fund_id!);
  const dispatch = useAppDispatch();  
  const curFund = fund.funds.find((f)=>f.id===fundID);

  console.log(curFund);

  useEffect(() => {
    if( (fund.holdings[fundID] === undefined || fund.holdings[fundID].length <= 0) && fund.isLoading === false && fund.error === null) {
      setIsBusy(true);
      dispatch( getFundHoldings(fundID) );
    }
  //eslint-disable-next-line
  },[]);

  useEffect(() => {
    if( fund.holdings[fundID] ) {
      let fArr:number[] = [];
      fund.holdings[fundID].map((arr) => {
        arr.map((holding) => {
        if( !fArr.find((e)=>e===holding.security_id) ) {
          fArr.push(holding.security_id);
        }
          return fundID;
        })
        return fundID;
      })
      setTickerArr( fArr.sort() );
      setHoldings( fund.holdings[fundID] );
      setIsBusy(false)
    }
  // eslint-disable-next-line
  },[fund.holdings[fundID]]);



  const HoldingTable = (props:{data:typeHolding[], tickArr: number[]}) : JSX.Element => {
    const {tickArr, data} = props;
    let sData  = [...data].sort((a,b)=>{ return ( a["security_id"] - (b["security_id"]) )  })   ;
    let rDate = (new Date(sData[0].reporting_date));
    let reportDate = rDate.getFullYear() + " Q" + parseInt( ((rDate.getMonth() / 3) + 1).toString() )

    return (
      <Table responsive="true"> 
      <tbody>
        <tr key={1}>
          <th colSpan={3}><Link to={`/thirteenfs/${sData[0].thirteenf_id}`}>{reportDate}</Link></th>
        </tr>
        <tr key={2}>
          <th>Ticker</th>
          <th>Shares</th>
          <th>Value</th>
        </tr>
        {
    tickArr.map((security_id)=>{
      const holding = sData.find(h=>h.security_id===security_id) as typeHolding;
      // return ({
          if( holding !== undefined ) 
            return (
              <tr key={uuid()}>
                <td>{holding.ticker}</td> 
                <td><FormattedNumber number={holding.shares}/></td>
                <td><FormattedNumber asMoney={true} number={holding.value}/></td>
              </tr>
            );
          else
            return (<tr key={uuid()}><td colSpan={3}>&nbsp;</td></tr>)
        // })
      })
    }
    </tbody>
    </Table>
    )

  }

  if(isBusy) return <Spinner />
  return (
    <>
    <Container>
      <FundCard fund={curFund}/>
      <Table responsive="true"> 
        <tbody>
          <tr key="abc123">
          {
            holdings.map(arr => { 
              return  <td key={arr[0].id}><HoldingTable tickArr={tickerArr} data={arr} /></td>
            })
          }
          </tr>
        </tbody>
      </Table>
    </Container>
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
  fund: state.fund as iFundState,
  appError: state.appError as iAppErrorState
});

const FundsContainer = connect(mapStateToProps)(Fund)
export default FundsContainer;