import { Button, Spinner } from 'react-bootstrap';

interface iButtonSpinner {
  buttonText: string;
  isLoading: boolean;
  onClick: () => void;
}

const ButtonSpinner = (props:iButtonSpinner) : JSX.Element => {
  const {buttonText, isLoading} = props;
  if( isLoading ) {
    return (
      <Spinner animation="border" role="status" />
    );
  } else {
    return (
      <Button active={!isLoading} onClick={() => props.onClick()}>
        {buttonText}
      </Button>
    );
  }

}

export default ButtonSpinner;