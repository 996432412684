import { Accordion } from "react-bootstrap";
import FormattedNumber from "./FormattedNumber";
import { typeFund } from "../platform/redux/fundsSlice";

export const FundCard = (props:{fund:typeFund|undefined}) : JSX.Element => {
  const {fund} = props;
  if( fund===undefined ) return <span/>;

  return (
    <Accordion className="flatAccordion">
    <Accordion.Item eventKey="0">
      <Accordion.Header>{fund.name}</Accordion.Header>
      <Accordion.Body>
        Total Value: <FormattedNumber asMoney={true} number={fund.total_value}/>
        <br/>
        CIK: {fund.cik}
        <br/>
        State: {fund.state}
        <br/>
        City: {fund.city}
      </Accordion.Body>
    </Accordion.Item>
    </Accordion>
    
  );
}