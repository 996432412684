import { useEffect, useState } from "react";
import { Container, Spinner, Table } from "react-bootstrap";
import { RootState } from "../platform/redux/store";
import { useAppDispatch } from "../hooks";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { iAppErrorState } from "../platform/redux/appErrorSlice";
import { getThirteenf, iFundState, typeThirteenf } from "../platform/redux/fundsSlice";
import FormattedNumber from "../components/FormattedNumber";
import FormattedDate from "../components/FormattedDate";
import { Link } from "react-router-dom";

interface iFunds {
  appError: iAppErrorState,
  fund: iFundState
}

const Thirteenf = (props:iFunds) => {
  const {fund} = props ;
  const [isBusy, setIsBusy] = useState(true);
  const [thirteenf, setThirteenf] = useState({} as typeThirteenf);

  const {thirteenf_id}  = useParams();
  const thirteenfID:number = parseInt(thirteenf_id!);
  const dispatch = useAppDispatch();  

  console.log(fund)

  useEffect(() => {
    console.log("useEffect()")
    if( fund.thirteenfs[thirteenfID] === undefined && fund.isLoading === false && fund.error === null) {
    console.log(" useEffect() - do dispatch")
      dispatch( getThirteenf(thirteenfID) );
    }
  //eslint-disable-next-line
  },[]);

  useEffect(() => {
    if( fund.thirteenfs[thirteenfID] ) {
      setThirteenf( fund.thirteenfs[thirteenfID] );
      setIsBusy(false)
    }
  // eslint-disable-next-line
  },[fund.thirteenfs]);


  if(isBusy) return <Spinner />
  return (
    <Container>
      <b>13F Filing for</b>: {thirteenf.fund.name}
      <br/>
      <br/>
      <Table responsive="true" size="sm"> 
        <tbody>
        <tr key={2}><th>File Number</th><td>{thirteenf.file_number}</td><td/></tr>
        <tr key={3}><th>Reporting Date</th><td><FormattedDate date={thirteenf.reporting_date}/></td><td/></tr>
        <tr key={4}><th>Date Loaded</th><td><FormattedDate date={thirteenf.loaded_date}/></td><td/></tr>
        <tr key={5}><th>Total Value</th><td><FormattedNumber asMoney={true} number={thirteenf.total_value}/></td><td/></tr>
        <tr key={1}><th>URL</th><td><Link to={thirteenf.url} target="_new">{thirteenf.url}</Link></td><td/></tr>
        </tbody>
      </Table>
      <br/>
      <Table responsive={true} size="sm" border={0}>
        <tbody>
          <tr key={101}><th colSpan={3}>Holdings</th></tr>
          <tr key={102}><th>Ticker</th><th>Shares</th><th>Value</th></tr>
          {
            thirteenf.holdings.map((holding) => {
              return (
                <tr key={holding.id}> 
                  <td>{holding.ticker || holding.cusip}</td>
                  <td><FormattedNumber number={holding.shares}/></td>
                  <td><FormattedNumber number={holding.value} asMoney={true}/> </td>
                </tr>
              );
            })
          }
        </tbody>
      </Table>
    </Container>
  )
}

const mapStateToProps = (state: RootState) => ({
  fund: state.fund as iFundState,
  appError: state.appError as iAppErrorState
});

const ThirteenfContainer = connect(mapStateToProps)(Thirteenf)
export default ThirteenfContainer;