import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Get, iServerError } from '../Server';
import { appError} from './appErrorSlice';
import { RootState } from './store';

export type typeFund = {
  id: number;
  name: string;
  manager: string;
  cik: string;
  total_value: number;
  title: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
}
export type typeHolding = {
  id: number;
  fund_id: number;
  security_id: number;
  thirteenf_id: number;
  shares: number;
  value: number;
  holding_type: string;
  share_class: string;
  sole: number;
  shared: number;
  none: number;
  ticker: string;
  cusip: string;
  reporting_date: Date;
  loaded_date: Date;
}

export type fundHoldings = {
  fund: typeFund;
  subfunds: typeFund[];
  holdings: typeHolding[][];
}
export type typeThirteenf = {
  id: number;
  url: string;
  file_number: string;
  loaded_date: Date;
  reporting_date: Date;
  total_value: number;
  holdings: typeHolding[];
  fund: typeFund;
}

interface HashMap<T> {
  [key: number]: T
}
export interface iFundState {
  error: string | null;
  isLoading: boolean;
  funds: typeFund[];
  holdings: HashMap<typeHolding[][]>;
  thirteenfs: HashMap<typeThirteenf>;
}

const initialState = {
  isLoading: false,
  error: null,
  funds: [],
  holdings: {},
  thirteenfs: {},
} satisfies iFundState as iFundState;

export const fundSlice = createSlice({
  name: 'funds',
  initialState,
  reducers: {
    congressError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFunds.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFunds.fulfilled, (state, action)  => {
        state.isLoading = false;
        state.funds = (action.payload === null ? [] : action.payload as typeFund[]) ;
      })
      .addCase(getFunds.rejected, (state)  => {
        state.isLoading = false;
      })
      .addCase(getFundHoldings.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFundHoldings.fulfilled, (state,action) => {
        const {fHoldings, fundID} = action.payload;
        state.isLoading = false;
        state.holdings[fundID] = fHoldings.holdings as typeHolding[][] ;
      })
      .addCase(getFundHoldings.rejected, (state)  => {
        state.isLoading = false;
      })
      .addCase(getThirteenf.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getThirteenf.fulfilled, (state,action) => {
        const {thirteenf, thirteenfID} = action.payload;
        state.thirteenfs[thirteenfID] = thirteenf as typeThirteenf;
        state.isLoading = false;
      })
      .addCase(getThirteenf.rejected, (state)  => {
        state.isLoading = false;
      })
  }
});

const mergeFunds = (state:typeFund[], newFunds:typeFund[]) : typeFund[] => {
  let fundArr: typeFund[] = [...state];
  //Only add trade ID's that don't exist (EG: closed)
  newFunds.map((data) => {
    const found = fundArr.find((tr)=>tr.id===data.id);
    if( !found ) fundArr.push( data );
    return data;
  })

  return fundArr;
}

const getFunds = createAsyncThunk(
  "congress/getFunds",
  async (args:any,{dispatch, getState, rejectWithValue}) => {
    try {
      const parms = args==="latest" ? "sort=latest" : "sort=popular"
      const funds = await Get(`funds.json?${parms}`) as typeFund[];
      const {fund} = getState() as RootState;
      return mergeFunds(fund.funds, funds);
    } catch(err) {
      dispatch( appError(err as iServerError) );
      return rejectWithValue(err);
    }
  }
);
const getThirteenf = createAsyncThunk(
  "congress/getThirteenf",
  async (thirteenfID:number,{dispatch, rejectWithValue}) => {
    try {
      const thirteenf = await Get(`thirteenfs/${thirteenfID}.json`) as typeThirteenf;
      console.log(thirteenf)
      return {thirteenf,thirteenfID}
    } catch(err) {
      dispatch( appError(err as iServerError) );
      return rejectWithValue(err);
    }
  }
);

const getFundHoldings = createAsyncThunk(
  "congress/getFundHoldings",
  async (fundID:number,{dispatch, getState, rejectWithValue}) => {
    try {
      const fHoldings:fundHoldings = await Get(`funds/${fundID}.json`) as fundHoldings;
      return { fHoldings, fundID };
    } catch(err) {
      dispatch( appError(err as iServerError) );
      return rejectWithValue(err);
    }
  }
);

// const { congressError } = congressSlice.actions;
export default fundSlice.reducer;
export {getFunds, getFundHoldings, getThirteenf};