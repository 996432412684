import React from "react";

const Home = () => {
  return (
    <React.Fragment>
    <h2>Home</h2>
    </React.Fragment>
  );
}

export default Home;