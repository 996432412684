interface iFormattedNumber {
  number: string|number;
  asMoney?: boolean;
}

const FormattedNumber = (props:iFormattedNumber) : JSX.Element => {
  const {number,asMoney} = props;
  if( number===null ) return <span/>;

  let numFloat = (typeof(number)==='string') ? parseFloat(number) : number;

  let num = Math.round( numFloat * 100 + Number.EPSILON ) / 100;
  let fDigits = (num.toString().split(/\./).length <= 1) ? 0 : 2;
  let numFormat = new Intl.NumberFormat('en-US', { 
    minimumIntegerDigits: 1, 
    minimumFractionDigits: fDigits 
  });
  let rNum = num < 0 ? "(" + numFormat.format(num) + ")" :numFormat.format(num);
  if(asMoney) rNum = "$" + rNum 

  return (
    <span>{rNum}</span>
  );
}
export default FormattedNumber;
