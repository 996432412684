import { Image} from 'react-bootstrap';
import { iAuthState } from '../platform/redux/authSlice';

interface iProps {
  auth: iAuthState;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const UserNavCard = (props:iProps) : JSX.Element => {
  return (
    <>
    {
      props.auth.user ?
        <div className='App-header-profile'>
          <Image src={props.auth.user?.picture} roundedCircle height={25} />
          { (props.auth.user?.email ? props.auth.user?.email.replace(/@.*/,'') : "") }
        </div>
      :
        "Account"
    }
    </>
  );
}

export default UserNavCard;