interface iFormattedDate {
  date: Date|string;
}

const FormattedDate = (props:iFormattedDate) : JSX.Element => {
  const {date} = props;
  if( date===null ) return <span/>;
  let fDate = typeof(date) === 'string' ? new Date(date) : date;

  const formattedDate = fDate.toLocaleDateString("en-US", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric"
  })

  return (
    <span>{formattedDate}</span>
  );
}
export default FormattedDate;
