import { useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { HashIDKey } from '../platform/redux/tradeSlice';

interface iProps {
  onSelected(val:number): void;
  data: HashIDKey;
  showAll?: boolean;
  cssID?: string;
  value?: number;
}

const DropDownIDList = (props:iProps) : JSX.Element => {
  const {onSelected, showAll, value, data, cssID} = props;
  const [selectItem, setSelectItem] = useState( value!==undefined ? value : 0);

  const onSelect = (ek:any, event:Object) => {
    setSelectItem( ek as number)
    onSelected( ek as number);
  }
  const title = value !== undefined ? data[value] : "";

  const item = (key:number) => {
    if( showAll !== true && +key === 0 ) return null;
    return <Dropdown.Item key={key} eventKey={key} active={key===selectItem ? true : false} >{data[key]}</Dropdown.Item>
  }

  return (
    <Dropdown>
      <DropdownButton title={title} id={cssID || "dropDownList"} onSelect={onSelect}>
        {
          Object.keys(data).map( (key:any) => {
            return item(key as number);
          })
        }
    </DropdownButton>
    </Dropdown>
  );
}

export default DropDownIDList;