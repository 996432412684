import { ChangeEvent, useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useAppDispatch } from "../hooks";
import { postTDTrade, tradeSources } from '../platform/redux/tradeSlice';
import { RootState } from '../platform/redux/store';
import { useSelector } from 'react-redux';
import ButtonSpinner from './ButtonSpinner';
import DropdownIDList from './DropdownIDList';

interface iTradeAdd {
  trade_number?: number;
  trade_source_id?: number;
  security_id?: number;
  small?: boolean;
}

const TradeAdd = (props:iTradeAdd) : JSX.Element => {
  const [tradeText, setTradeText] = useState("");
  const [tradeSource, setTradeSource] = useState(props.trade_source_id || 1);

  const dispatch = useAppDispatch();  
  const trade = useSelector((state:RootState) => state.trade )

  useEffect(() => {
    // console.log("tradeAdd.useEffect()", trade.isLoading);
    if( !trade.isLoading ) setTradeText("");
  },[trade.isLoading]);

  const txtChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTradeText(event.currentTarget.value);
  }

  const addTrade = () => {
    if( tradeText.length < 10 ) return;
    dispatch( 
      postTDTrade( {trade_source_id: tradeSource, order_text: tradeText, trade_number: props.trade_number || null} ) 
    );
  }

  let height = '100px';
  let width = '100%'
  if(props.small!==undefined) {
    height = '50px';
    width = '50%';
  } 

  return (
    <Container id="trade-add-container" style={{ width: width }} className="align-items-center">
      <Row>
        <Col>
          <Form.Control as="textarea" value={tradeText}  placeholder="Paste Thinkorswim trade here" style={{ height: height }} onChange={txtChange}/>
        </Col>
        <Col xs={3}>
            {props.trade_source_id ? "" : <DropdownIDList cssID="dropDownListHeader" data={tradeSources} value={tradeSource} onSelected={setTradeSource}/>}
            <br/>
            <ButtonSpinner isLoading={trade.isLoading} buttonText="Add Trade" onClick={addTrade}/>
        </Col>
      </Row>
    </Container>
  );
}

export default TradeAdd;