import { useEffect, useState } from "react";
import { Container, Table, Form, Spinner, Button } from "react-bootstrap";
import { RootState } from "../platform/redux/store";
import { callPut, iTradesState, tradeSources, tradeStatuses, tradeStructures, tradeTypes, typeTrade, updateTrade } from "../platform/redux/tradeSlice";
import { useAppDispatch } from "../hooks";
import { connect, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { ArrowLeftCircle } from "react-bootstrap-icons";
import EditDateField from "../components/EditDateField";
import DropdownList from "../components/DropdownList";
import DropDownIDList from "../components/DropdownIDList";

interface iTrades {
  trade: iTradesState,
}

const TradeEdit = (props:iTrades) => {
  const {trade} = props;
  const {trade_id}  = useParams();
  const tradeID:number = parseInt(trade_id!);
  const [editTrade, setEditTrade] = useState({} as typeTrade);
  const [tradeUpdates, setTradeUpdates] = useState({} as typeTrade);
  const [backItems, setBackItems] = useState([0,0]);
  const [updating, setUpdating] = useState(false);
  const dispatch = useAppDispatch();  

  useEffect(() => {
    const tr = (trade.trades.find((e)=>e["id"]===tradeID) || {}) as typeTrade;
    setEditTrade( tr );
    setBackItems( [tr.trade_number, tr.trade_source_id ]); //Set from initial trade since trade#, sourceID can change
  // eslint-disable-next-line
  },[]);

  const tradeState = useSelector((state:RootState) => state.trade )
  useEffect(() => {
    setUpdating(tradeState.isLoading)
    if( tradeState.isLoading === false && Object.keys(tradeUpdates).length > 0) 
      window.location.href = `/trades/${backItems[0]}/${backItems[1]}`;
  //eslint-disable-next-line
  },[tradeState.isLoading]);


  const onChange = (name:string, value:any, type:string) => {
    let val = type==="number" ? Number(value) : value;
      setEditTrade({
        ...editTrade,
        [name]: val
      })
      setTradeUpdates({
        ...tradeUpdates,
        [name]: val
      })
  }

  const doUpdate = () => {
    if( Object.keys(tradeUpdates).length <= 0 ) return;
    setUpdating(true);
    dispatch( updateTrade({tradeID: tradeID, updateData: tradeUpdates} ));
  }

  if( editTrade.id === undefined ) return <Spinner animation="border" role="status" />

  if( updating ) return (
      <Container>
        <Spinner />
      </Container>
  )

  return (
    <>
    <Container>
    <Form>
      <Link to={`/trades/${backItems[0]}/${backItems[1]}`}><ArrowLeftCircle/></Link>
      <Table bordered hover className="editTable">
        <tbody>
          <tr>
            <td>Ticker:</td>
            <td><Form.Control size="sm" type="text" onChange={(evt)=>onChange("ticker", evt.currentTarget.value, "text")} value={editTrade.ticker}/></td>
          </tr>
          <tr>
            <td>Trade Number:</td>
            <td><Form.Control size="sm" type="text" onChange={(evt)=>onChange("trade_number", evt.currentTarget.value, "number")} value={editTrade.trade_number}/></td>
          </tr>
          <tr>
            <td>Type:</td>
            <td>
            <DropdownList data={tradeTypes} value={editTrade.trade_type} onSelected={(sel)=>{onChange("trade_type", sel, "string")}}/>
            </td>
          </tr>
          <tr>
            <td>Status:</td>
            <td>
            <DropdownList data={tradeStatuses} value={editTrade.trade_status} onSelected={(sel)=>{onChange("trade_status", sel, "string")}}/>
            </td>
          </tr>
          <tr>
            <td>Quantity:</td>
            <td><Form.Control size="sm" type="text" onChange={(evt)=>onChange("quantity", evt.currentTarget.value, "number")} value={editTrade.quantity}/></td>
          </tr>
          <tr>
            <td>Trade Date:</td>
            <td><EditDateField noSpinner={true} date={new Date(editTrade.trade_date)} onChanged={(d)=>onChange("trade_date", d, "date")} /></td>
          </tr>
          <tr>
            <td>Price:</td>
            <td><Form.Control size="sm" type="text" onChange={(evt)=>onChange("trade_price", evt.currentTarget.value, "number")} value={editTrade.trade_price}/></td>
          </tr>
          <tr>
            <td>Trade Source:</td>
            <td>
            <DropDownIDList data={tradeSources} value={editTrade.trade_source_id} onSelected={(sel)=>{onChange("trade_source_id", sel, "number")}}/>
            </td>
          </tr>
          <tr>
            <td>Expiration Date:</td>
            <td>
              <EditDateField noSpinner={true} date={editTrade.expiration_date ? new Date(editTrade.expiration_date) : null} onChanged={(d)=>onChange("expiration_date", d, "date")} />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>------------ Options --------------------</td>
          </tr>
          <tr>
            <td>Call/Put:</td>
            <td>
              <DropdownList data={callPut} value={editTrade.call_put} onSelected={(sel)=>{onChange("call_put", sel, "string")}}/>
            </td>
          </tr>
          <tr>
            <td>Structure:</td>
            <td>
              <DropDownIDList data={tradeStructures} value={editTrade.structure_id} onSelected={(sel)=>{onChange("structure_id", sel, "number")}}/>
              </td>
          </tr>
          <tr>
            <td>Strikes:</td>
            <td><Form.Control size="sm" type="text" onChange={(evt)=>onChange("trade_strikes", evt.currentTarget.value, "text")} value={editTrade.trade_strikes || ""}/></td>
          </tr>
          <tr>
            <td>Strike Quantities:</td>
            <td><Form.Control size="sm" type="text" onChange={(evt)=>onChange("strike_quantities", evt.currentTarget.value, "string")} value={editTrade.strike_quantities || ""}/></td>
          </tr>
          <tr>
            <td>Order Text:</td>
            <td>{editTrade.order_text}</td>
          </tr>
          <tr>
            <td>
              <Button variant="primary" onClick={()=>doUpdate()} >Update</Button>
            </td>
            <td/>
          </tr>
        </tbody>
      </Table>
    </Form>
    </Container>
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
  trade: state.trade as iTradesState
});

const TradeEditContrainer = connect(mapStateToProps)(TradeEdit);
export default TradeEditContrainer;