import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Delete, Get, iServerError, Post, Put } from '../Server';
import { appError } from './appErrorSlice';
import { RootState } from './store';

/*
  {"id":991, "security_id":68437,"parent_id":null,"trade_number":1418,"trade_type":"Option","order":0,"trade_status":"Closed","buy_sell":"Buy","call_put":"Put",
  "to_action":"Open","quantity":8,"trade_date":"2024-02-12T20:02:41.000Z","trade_price":"-1.85","trade_cost":null,"expiration_date":"2024-02-16T00:00:00.000Z",
  "expiration_dates":null,"trade_strikes":"610/555","strike_quantities":"",
  "closed_date":"2024-02-12T20:03:34.000Z","created_at":"2024-02-12T20:02:41.911Z","updated_at":"2024-02-12T20:03:34.031Z",
  "order_text":"BOT +4 VERTICAL MSTR 100 16 FEB 24 610/555 PUT @1.85 ISE",
  "open_status":0,"ticker":"MSTR","name":"Micro Strategy Incorporated ","structure":"Vertical","trade_source":"Shadow Trader","security":"MSTR"}
*/
export type typeTrade = {
  id: number;
  security_id: number;
  parent_id: number | null;
  trade_number: number;
  trade_source_id: number;
  trade_source?: string;
  trade_type: string;
  trade_status: string;
  buy_sell: string;
  call_put: string;
  quantity: number;
  trade_date: string;
  trade_price: number;
  trade_cost: number;
  expiration_date: string;
  ticker: string;
  name: string;
  structure: string;
  structure_id: number;
  expiration_dates: string | null;
  trade_strikes: string | null;
  strike_quantities: string | null;
  order_text: string;
}

export type typeTradeOrder = {
  trade_source_id: number;
  trade_number: number|null;
  order_text: string;
}
export type HashIDKey = {
  [key: number]: string;
}

export type HashCharKey = {
  [key: string]: string;
}

export const tradeStructures = {
  1: "Single",
  2: "Covered Call",
  3: "Vertical",
  4: "Ratio",
  5: "Back Spread",
  6: "Butterfly",
  7: "Broken Wing Butterfly",
  8: "Unbalanced Butterfly",
} as HashIDKey;

export const callPut = {
  "c": "Call",
  "p": "Put"
} as HashCharKey;

export const tradeStatuses = {
  "o": "Open",
  "c": "Closed",
  "w": "Working"
} as HashCharKey;

export const tradeTypes  = {
  "a":"All",
  "o":"Option",
  "s":"Stock",
  "f":"Forex",
  "b":"Bond"
} as HashCharKey;


export const tradeSources  = {
  0:"All",
  1:"Me",
  2:"Shadow Trader",
  3:"Zip Trader",
  4:"CTA (Van Meter)",
} as HashIDKey;

interface HashMap<T> {
  [key: number]: T
}
export interface iTradesState {
  isLoading: boolean;
  isError: boolean;
  openLoaded: boolean;
  closeLoaded: boolean;
  tradeDetails: HashMap<boolean>;
  trades: typeTrade[];
}

const initialState = {
  isLoading: false,
  isError: false,
  openLoaded: false,
  closeLoaded: false,
  tradeDetails: {},
  trades: []
} satisfies iTradesState as iTradesState;

export const tradeSlice = createSlice({
  name: 'trades',
  initialState,
  reducers: {
    addTradePending: (state) => {
      state.isLoading = true;
    },
    openLoaded: (state) => {
      state.openLoaded = true;
    },
    closeLoaded: (state) => {
      state.closeLoaded = true;
    },
    addTrade: (state, action: PayloadAction<typeTrade>) => {
      state.trades.push(action.payload);
      state.isLoading= false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTrades.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getTrades.fulfilled, (state, action)  => {
        state.isLoading = false;
        state.isError = false;
        state.trades = (action.payload === null ? [] : action.payload as typeTrade[]) ;
      })
      .addCase(getTrades.rejected, (state)  => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(getTrade.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getTrade.fulfilled, (state, action) => {
        const {tradeArr, tradeNumber} = action.payload;
        state.isLoading = false;
        state.isError = false;
        if( tradeArr.length > 0 ) {
          state.tradeDetails[tradeNumber] = true;
          state.trades = tradeArr;
        }
      })
      .addCase(getTrade.rejected, (state)  => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(updateTrade.pending, (state)  => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(updateTrade.fulfilled, (state,action)  => {
        state.trades = action.payload;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(updateTrade.rejected, (state)  => {
        state.isLoading = false;
      })
      .addCase(deleteTrade.pending, (state)  => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(deleteTrade.fulfilled, (state,action)  => {
        state.trades = action.payload;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(deleteTrade.rejected, (state)  => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(closeTrade.pending, (state)  => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(closeTrade.fulfilled, (state,action)  => {
        state.trades = action.payload;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(closeTrade.rejected, (state)  => {
        state.isLoading = false;
        state.isError = true;
      })
  }
});

const addNewTrades = (state:typeTrade[], newTrades:typeTrade[]) : typeTrade[] => {
  let tradeArr: typeTrade[] = [...state];
  //Only add trade ID's that don't exist (EG: closed)
  newTrades.map((data) => {
    const found = tradeArr.find((tr)=>tr.id===data.id);
    if( !found ) tradeArr.push( data );
    return data;
  })

  return tradeArr;
}

const getOpenTrades = () => getTrades("open=1");
const getClosedTrades = () => getTrades("limit=2000");
const getTicker = (ticker:string) => getTrades(`ticker=${ticker}`);

const getTrades = createAsyncThunk(
  "trades/getTrades",
  async (params:string, {dispatch, getState, rejectWithValue}) => {
    try {
      const {trade} = getState() as RootState;
      const newTrades = await Get(`trades.json?${params}`) as typeTrade[];
      if( params==="open=1") dispatch( openLoaded() );
      if( params.match(/limit/) ) dispatch( closeLoaded() );
      return addNewTrades(trade.trades, newTrades);
    } catch(err) {
      dispatch( appError(err as iServerError) );
      return rejectWithValue(err);
    }
  }
);

const getTrade = createAsyncThunk(
  "trades/getTrade",
  async (params:any, {dispatch, getState, rejectWithValue}) => {
    try {
      const {tradeNumber,tradeSourceID} = params;
      const {trade} = getState() as RootState;
      const allTrades = await Get(`trades/${tradeNumber}.json?source_id=${tradeSourceID}`) as typeTrade[];

      var tradeArr: typeTrade[] = [];
      if( allTrades===null ) {
        return {tradeArr, tradeNumber};
      }

      tradeArr = [...trade.trades];
      //Only add trade ID's that don't exist (EG: closed)
      allTrades.map((data) => {
        const found = tradeArr.find((tr)=>tr.id===data.id);
        if( !found ) tradeArr.push( data );
        return data;
      })
      return {tradeArr,tradeNumber};
    } catch(err) {
      dispatch( appError(err as iServerError) );
      return rejectWithValue(err);
    }
  }
);

const closeTrade = createAsyncThunk(
  "trades/closeTrade",
  async (tradeID:number, {dispatch, getState, rejectWithValue}) => {
    try {
      const {trade} = getState() as RootState;
      const uTrade = await Post(`trades/${tradeID}/close.json`, {id: {tradeID}} );
      let tradeArr: typeTrade[] = [];
      trade.trades.map((data) => {
        if(data.id === tradeID) {
          tradeArr.push( uTrade as typeTrade );
        } else {
          tradeArr.push( data );
        }
        return data;
      });

      return tradeArr;
    } catch(err) {
        dispatch( appError(err as iServerError) );
        return rejectWithValue(err);
    }
  }
);

const deleteTrade = createAsyncThunk(
  "trades/deleteTrade",
  async (tradeID:number, {dispatch, getState, rejectWithValue}) => {
    try {
      await Delete(`trades/${tradeID}.json`);
      const {trade} = getState() as RootState;
      let tradeArr: typeTrade[] = [];
      trade.trades.map((data) => {
        if(data.id !== tradeID) tradeArr.push( data );
        return data;
      });

      return tradeArr;
    } catch(err) {
        dispatch( appError(err as iServerError) );
        return rejectWithValue(err);
    }
  }
);

const updateTrade = createAsyncThunk(
  "trades/updateTrade",
  async (params:any, {dispatch, getState, rejectWithValue}) => {
    try {
      const {tradeID,updateData} = params;
      const {trade} = getState() as RootState;
      let tradeArr: typeTrade[] = [];

      const uTrade = await Put(`trades/${tradeID}.json`, {trade: updateData} ) as typeTrade;
      trade.trades.map((data) => {
        if(data.id === tradeID) {
          tradeArr.push( uTrade );
        } else {
          tradeArr.push( data );
        }
        return data;
      });

      return tradeArr;
    } catch(err) {
        dispatch( appError(err as iServerError) );
        return rejectWithValue(err);
    }
  }
);

const postTDTrade = (orderParam:typeTradeOrder) => (dispatch:any) => {
  if( orderParam.trade_source_id === undefined 
    ||orderParam.trade_source_id < 1 
    || orderParam.trade_source_id > 4
  ) {
    dispatch( appError({success: false, error:{message: "Missing proper trade source!", code: 404}}) );
  }
  dispatch( addTradePending() );
  Post('trades.json', {trade: orderParam} )
  .then((trade) => {
    dispatch( addTrade(trade as typeTrade) )
  })
  .catch((err) => {
    dispatch( appError(err as iServerError) );
  })
}

/*
const postTDTrade = createAsyncThunk(
  "trades/portTDTrade",
  async (orderText:string, {dispatch, rejectWithValue}) => {
    try {
      const newTrade = await Post('trades.json', orderText, true);
      return newTrade;
    } catch(err) {
      dispatch( appError(err as iServerError) );
      return rejectWithValue(err);
    }
  }
);
*/

const { addTrade, addTradePending, openLoaded, closeLoaded } = tradeSlice.actions;
export default tradeSlice.reducer;
export {getTrades, getOpenTrades, getClosedTrades, getTrade, getTicker, postTDTrade, updateTrade, closeTrade, deleteTrade}