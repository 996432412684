export const platformConfig = {
  apiKey: "dontHave",
  apiUrl: "https://api.hangintooth.com/api/v1",
  production: true
};

export const productionHosts = [
  "hangintooth.com",
  "www.hangintooth.com",
  "api.hangintooth.com"
];