// store.ts
import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit'
import authSlice from './authSlice';
import tradeSlice from './tradeSlice';
import errorSlice from './appErrorSlice';
import congressSlice from './congressSlice';
import fundSlice from './fundsSlice';

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

const rootReducer = combineReducers({
  auth: authSlice,
  trade: tradeSlice,
  appError: errorSlice,
  congress: congressSlice,
  fund: fundSlice,
});

const store = configureStore({
  reducer: rootReducer,
})

export default store;