import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { iAuthState } from '../platform/redux/authSlice';

interface iProps {
  auth: iAuthState;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

const UserCard = (props:iProps) : JSX.Element => {
  return (
    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src={props.auth.user?.picture} />
      <Card.Body>
        <Card.Title>{props.auth.user?.email}</Card.Title>
        <Card.Text>
          {/* Session Expire: {new Date( props.auth.expires).toLocaleString()} */}
        </Card.Text>
        <Button variant="primary" onClick={props.onClick}>Logout</Button>
      </Card.Body>
    </Card>
  );
}

export default UserCard;