import { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { RootState } from "../platform/redux/store";
import { getTrade, iTradesState, typeTrade } from "../platform/redux/tradeSlice";
import { useAppDispatch } from "../hooks";
import { connect } from "react-redux";
import {ProfitLossRow, TradeHeader, TradeRow} from "../components/TradeTable";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { ArrowLeftCircle } from "react-bootstrap-icons";

interface iTrades {
  trade: iTradesState,
}

const TradeShow = (props:iTrades) => {
  const {trade} = props ;
  const {trade_id, trade_source_id}  = useParams();
  const tradeNumber:number = parseInt(trade_id!);
  const tradeSourceID:number = parseInt(trade_source_id!);

  const [trades, setTrades] = useState([] as typeTrade[]);
  const dispatch = useAppDispatch();  

  useEffect(() => {
    if( !trade.tradeDetails[ tradeNumber ] && trade.isError===false ) {
      dispatch( getTrade({tradeNumber:tradeNumber,tradeSourceID:tradeSourceID}) );
    }
  // eslint-disable-next-line
  },[trade.tradeDetails]);

  useEffect(() => {
    let tradeNos:typeTrade[] = [];
    trade.trades.map((trade) => {
        if( trade.trade_number === tradeNumber && trade.trade_source_id === tradeSourceID) tradeNos.push( trade );
        return trade;
      });
    setTrades( tradeNos );
  // eslint-disable-next-line
  },[trade.trades]);

  const sortedTrades = () : (typeTrade[]) => {
    if( trades.length <= 0 ) return [];
    const sorted = [...trades].sort( (a, b) => {
      return (new Date(b['trade_date']).getTime()) - (new Date(a['trade_date']).getTime()) ;
    });
    return sorted;
  }

  return (
    <>
    <Container>
      <Link to="/trades"><ArrowLeftCircle/></Link>
      <Table bordered hover>
        <TradeHeader detailPage={true} tradeTypeChange={()=>{}} tradeSourceChange={()=>{}} trade={sortedTrades()[0]}/>
        <tbody>
        {
          sortedTrades().map(data => {
            return (
              <TradeRow detailPage={true} key={data.id} trade={data}/>
            )
          })
        }
        <ProfitLossRow trades={sortedTrades()} />
        </tbody>
      </Table>
    </Container>
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
  trade: state.trade as iTradesState
});

const TradeShowContrainer = connect(mapStateToProps)(TradeShow);
export default TradeShowContrainer;