import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import  {RootState} from './platform/redux/store';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Container } from 'react-bootstrap';
import { iAuthState } from './platform/redux/authSlice';
import { iAppErrorState } from './platform/redux/appErrorSlice';

import './App.css';

import Home from './pages/Home';
import Account from './pages/Account';
import Trades from './pages/Trades';
import UserNavCard from './components/UserNavCard';
import ErrorCard from './components/ErrorCard';
import TradeShow from './pages/TradeShow';
import TradeEdit from './pages/TradeEdit';
import Congresses from './pages/Congresses';
import Congress from './pages/Congress';
import Funds from './pages/Funds';
import Fund from './pages/Fund';
import Thirteenf from './pages/Thirteenf';

interface iApp {
  auth: iAuthState;
  appError: iAppErrorState;
}

function App(props:iApp) {
  const {auth,appError} = props;

  const PrivateRoutes = () => {
    return (
      (auth.expires > (new Date().getTime()))  && !(appError.error?.code === 401) ? <Outlet /> : <Navigate to="/account" />
      // (auth.state && !(appError.error?.code === 401) )? <Outlet /> : <Navigate to="/account" />
    )
  }

  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary" sticky="top">
      <Container>
        <Navbar.Brand href="/">Trade Tracker</Navbar.Brand>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav>
           <Nav.Link href="/trades">My Trades</Nav.Link>
           <Nav.Link href="/congresses">Congress</Nav.Link>
           <Nav.Link href="/funds">Funds</Nav.Link>
          </Nav>
          <Nav className="ms-auto">
            <Nav.Link href="/account"> <UserNavCard auth={auth} /> </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <ErrorCard />
    <br/>

      <Routes>
        <Route path="/" element={ <Home /> } />
        <Route path="/account" element={ <Account /> } />
        <Route element={<PrivateRoutes/>}>
          <Route path="/trades" element={ <Trades /> } />
          <Route path="/trades/:trade_id/:trade_source_id" element={ <TradeShow /> } />
          <Route path="/trades/:trade_id/edit" element={ <TradeEdit /> } />
          <Route path="/congresses" element={ <Congresses /> } />
          <Route path="/congress/:congress_id" element={ <Congress /> } />
          <Route path="/funds" element={ <Funds /> } />
          <Route path="/funds/:fund_id" element={ <Fund /> } />
          <Route path="/thirteenfs/:thirteenf_id" element={ <Thirteenf /> } />
        </Route>
      </Routes>
  </>
  );
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  appError: state.appError,
});

const AppContainer = connect( mapStateToProps,)(App);
export default AppContainer;