import { useEffect, useState } from "react";
import { Container, Spinner, Table } from "react-bootstrap";
import { RootState } from "../platform/redux/store";
import { useAppDispatch } from "../hooks";
import { connect } from "react-redux";
import { appError, iAppErrorState } from "../platform/redux/appErrorSlice";
import { Post, iServerError } from "../platform/Server";
import { getFunds, iFundState, typeFund } from "../platform/redux/fundsSlice";
import FormattedNumber from "../components/FormattedNumber";
import { Link } from "react-router-dom";

interface iFunds {
  appError: iAppErrorState,
  fund: iFundState
}

const Funds = (props:iFunds) => {
  const {fund} = props ;
  const [isBusy, setIsBusy] = useState(true);
  const [searchTxt, setSearchTxt] = useState("");
  const [funds, setFunds] = useState([] as typeFund[]);
  const [showFunds, setShowFunds] = useState("Show Latest");
  const dispatch = useAppDispatch();  


  const doGetFunds = (parms:string) => {
    setIsBusy(true);
    dispatch( getFunds(parms) );
  }

  useEffect(() => {
    if (fund.funds.length <= 0 && fund.isLoading === false && fund.error === null) {
      doGetFunds("");
    }
  //eslint-disable-next-line
  },[]);

  useEffect(() => {
    setFunds( fund.funds );
    setIsBusy(false)
  // eslint-disable-next-line
  },[fund.funds]);

  const getData = (srch:string) => {
    setSearchTxt(srch)
    if( srch.length < 3 ) return;
    Post(`funds/find`, {name: searchTxt})
      .then(data => {
        setFunds(data as typeFund[])
      })
      .catch((err:iServerError)=>{
        dispatch( appError(err) );
      })
  }

  const fundsClick = () => {
    if( showFunds === "Show Latest" ) {
      doGetFunds("latest")
      setShowFunds("Show Popular");
    } else {
      doGetFunds("popular")
      setShowFunds("Show Latest");
    }
  }

  return (
    <>
    <Container>
      {isBusy && <Spinner />}
      <Link to="" onClick={fundsClick}>{showFunds}</Link>
      <Table striped bordered hover size="sm" responsive="true">
        <thead>
          <tr style={{verticalAlign: "middle" }}>
            <th>Name</th>
            <th>Manager</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
        {
          funds.map(f => {
            return (
              <tr key={f.id}>
                <td>
                  <Link to={`/funds/${f.id}`}>{f.name}</Link>
                </td>
                <td>{f.manager}</td>
                <td><FormattedNumber asMoney={true} number={f.total_value.toString()}/> </td>
              </tr>
            )
          })
        }
        </tbody>
      </Table>
    </Container>
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
  fund: state.fund as iFundState,
  appError: state.appError as iAppErrorState
});

const FundsContainer = connect(mapStateToProps)(Funds)
export default FundsContainer;