import * as development from './development';
import * as production from './production';

interface iPlatformConfig {
  apiKey: string;
  apiUrl: string;
  production: boolean;
}

let platformConfig: iPlatformConfig;
let SERVER_BADGE: String;

if( production.productionHosts.includes(window.location.hostname) ) {
  platformConfig = production.platformConfig;
  SERVER_BADGE = "Production Server";
} else {
  platformConfig = development.platformConfig;
  SERVER_BADGE = "Development Server";
}

export { SERVER_BADGE, platformConfig};