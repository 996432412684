import { useState } from 'react';
import { Alert, Container } from 'react-bootstrap';
import { RootState } from '../platform/redux/store';
import { connect } from 'react-redux';
import { iAppErrorState } from '../platform/redux/appErrorSlice';

interface iErrorCard {
  appError: iAppErrorState,
}

const ErrorCard = (props:iErrorCard) : JSX.Element => {
  const {appError} = props;
  const [show,setShow] = useState(true);

  return (
    <>
    {
      show && appError.isError && ( 
        <Container>
        <Alert variant="danger" onClose={() => setShow(false)} dismissible> 
          <Alert.Heading>Error {appError.error?.code}</Alert.Heading> 
            <p>{appError.error?.message }</p> 
        </Alert> 
        </Container>
     )
    }
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  appError: state.appError,
});

const ErrorCardContainer = connect( mapStateToProps,)(ErrorCard);
export default ErrorCardContainer;
