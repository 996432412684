import { ChangeEvent, KeyboardEvent, useState } from "react";
import { Form } from "react-bootstrap";

interface iTradeNumberSearch {
  onChanged?: {(tradeNumber:string):void};
  title: string;
}

const EditSearchField = (props:iTradeNumberSearch) => {
  const {onChanged,title} = props;
  const [isEditing,setIsEditing] = useState(false);
  const [editVal,setEditVal] = useState("");

  const onChange = (event:ChangeEvent<HTMLInputElement>) => setEditVal(event.currentTarget.value);
  const editChangeEnter =  (event:KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Escape" || event.key === "Tab") {
      setEditVal("")
      setIsEditing(false);
      event.currentTarget.blur();
      (onChanged) && onChanged("");
      return;
    }

    if (event.key === "Enter") {
      setIsEditing(false);
      (onChanged) && onChanged(event.currentTarget.value);
    }
  }

  return (
    <>
      {isEditing ? 
        <Form.Control as="input" value={editVal} onChange={onChange} onKeyDown={editChangeEnter} autoFocus onBlur={()=>setIsEditing(false)}/>
        :
        <span onClick={()=>setIsEditing(true)}>{editVal ? `${title}: ${editVal}` : title}</span>
      }
    </>
  );

}

export default EditSearchField;